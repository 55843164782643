import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {PaymentsService} from "../../services/payments.service";
import {environment} from "../../../../../bringit_pay/src/environments/environment";

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {

  paidMsg!: string;
  errMsg!: string;

  data = {
    business_id: '',
    token: '',
  };

  constructor() {
    this.paidMsg = "בוצע תשלום בהצלחה, תכף תועבר לדף מעקב אחר הזמנה";
  }

  ngOnInit(): void {
  }

}
