import {PaymentComponent} from "./pages/payment/payment.component";
import {NotFoundComponent} from "./pages/not-found/not-found.component";
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ThankYouComponent} from "./pages/thank-you/thank-you.component";
import {ErrorComponent} from "./pages/error/error.component";
import {CancelComponent} from "./pages/cancel/cancel.component";

const routes: Routes = [
  {path: 'payment/:business_id/:total/:order_token', component: PaymentComponent},
  {path: 'payment-success/:business_id/:order_token', component: ThankYouComponent},
  {path: 'error', component: ErrorComponent},
  {path: 'cancel', component: CancelComponent},
  {path: '**', component: NotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
