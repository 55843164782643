import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from "@angular/router";
import {PaymentsService} from "../../services/payments.service";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

interface ICalResponse {
  status: string,
  key: string,
  message: string,
}

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  business_id?: string;
  total?: number;
  errMsg?: string;
  order_token?: string;
  imgRoot: string;

  constructor(
    private paymentsService: PaymentsService,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {
    this.route.params.subscribe((params: Params) => {
      this.business_id = params['business_id'];
      this.total = parseFloat(params['total']);
      this.order_token = params['order_token'];
    })
    this.imgRoot = `${environment.files}images`;
  }

  ngOnInit(): void {
  }

}
