import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(private http: HttpClient) { }

  validateOrder(data: any) {
    const url = `${environment.api2}orders/credit/validate`;
    return this.http.post(url, data)
  }

  orderPaid(data: any) {
    const url = `${environment.api2}orders/credit/paid`;
    return this.http.post(url, data)
  }

  getCalKey(data: any) {
    const url = `${environment.api1}pizziria&do=getCalKey`;
    return this.http.post(url, data)
  }
}
