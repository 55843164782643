<div class="payment-container">
  <div class="well">
    <div>
      <div class="bringit-section">
        <p class="lead">
          העסקה מבוצעת באמצעות
          <br>
          Bringit Technologies
          <br>
          שירותי ניהול משלוחים לבתי עסק ולקוחות
        </p>
        <img [src]="imgRoot+'/bringit/logo.png'" alt="">
      </div>

      <div class="cg-section">

      </div>
    </div>
  </div>
</div>
