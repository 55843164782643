import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {CancelComponent} from "./pages/cancel/cancel.component";
import {ErrorComponent} from "./pages/error/error.component";
import {ThankYouComponent} from "./pages/thank-you/thank-you.component";
import {NotFoundComponent} from "./pages/not-found/not-found.component";
import {RequestInterceptor} from "./services/interceptors/request.interceptor";
import {PaymentComponent} from "./pages/payment/payment.component";

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    PaymentComponent,
    ThankYouComponent,
    ErrorComponent,
    CancelComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
